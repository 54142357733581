<template>
  <div>
    <div class="login d-flex justify-content-lg-between w-100">
      <div class="col-12 col-lg-4 bg-white">
        <div class="d-flex flex-column justify-content-center align-items-center h-100">
          <router-link class="logo d-lg-none" :to="'/'">
            <img src="/images/layout/logo-gray-pixel-roads.png" alt="Pixel Roads" />
          </router-link>
          <h1 class="fw-800">
            {{ $t('welcome') }}
          </h1>
          <p>{{ $t('login_to_get_started') }}</p>
          <div class="login-form">
            <form class="default-form" @submit.prevent="login">
              <div class="form-group mb-4">
                <label class="form-label font-size-medium">{{ $t('email') }}</label>
                <input type="email" class="form-control" placeholder="email@email.com.br" v-model.trim="$v.form.email.$model" />
              </div>
              <div class="form-group">
                <label class="form-label font-size-medium">{{ $t('password') }}</label>
                <input type="password" class="form-control" :placeholder="$t('enter_your_password')" v-model.trim="$v.form.password.$model" autocomplete="new-password" />
              </div>
              <div class="mt-3 text-end">
                <a href="#" @click.prevent="$bvModal.show('forgot-password-modal')" class="link-color2">{{ $t('forgot_password') }}</a>
              </div>
              <div class="mt-3">
                <Button
                  type="submit"
                  class="btn btn-primary fw-500 w-100"
                  :disabled="$v.$invalid"
                  :loading="loader"
                >
                  {{ $t('login') }}
                </Button>
              </div>
              <div class="mt-3 text-center">
                {{ $t('are_you_new_here') }}
              </div>
              <div class="mt-3">
                <Button
                  type="button"
                  class="btn btn-color1 fw-500 w-100"
                  @click="$bvModal.show('registration-modal')"
                >
                  {{ $t('sign_up_now') }}!
                </Button>
              </div>
            </form>
          </div>
          <TheFooter class="d-lg-none" />
        </div>
      </div>
      <RegistrationModal
        :step="registrationStep"
        :userEmail="userEmail"
        @closed-modal="handleClosedModal"
      />
      <ForgotPasswordModal />
      <TwoFactorAuthenticationModal
        :userEmail="userEmail"
      />
      <div class="col-12 col-lg-8 px-5 d-none d-lg-block">
        <div class="d-flex flex-column justify-content-center gap-5 h-100 mx-xxl-5">
          <div class="d-flex justify-content-end default-form">
            <select v-model="$i18n.locale" class="form-select font-size-small border-0 w-auto" @change="handleLanguageChange">
              <option value="pt_BR">PT</option>
              <option value="en" selected>EN</option>
              <option value="es">ES</option>
            </select>
          </div>
          <img src="/images/pages/home/home-mockup-pixel-roads.png" alt="Mockup" />
          <TheFooter />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import AuthService from '@/modules/auth/services/auth-service'
import UsersService from '@/modules/users/services/users-service'
import { required, email } from 'vuelidate/lib/validators'

import Button from '@/components/common/Button/Button'
import ForgotPasswordModal from '@/components/auth/ForgotPasswordModal'
import RegistrationModal from '@/components/auth/RegistrationModal'
import TheFooter from '@/components/footers/TheFooter'
import TwoFactorAuthenticationModal from '@/components/auth/TwoFactorAuthenticationModal'

export default {
  name: 'Login',
  components: {
    Button,
    ForgotPasswordModal,
    RegistrationModal,
    TheFooter,
    TwoFactorAuthenticationModal
  },
  data () {
    return {
      registrationStep: 'registration',
      userEmail: '',
      loader: false,
      form: {
        email: '',
        password: ''
      }
    }
  },
  validations () {
    return {
      form: {
        email: { email, required },
        password: { required }
      }
    }
  },
  created () {
    this.handleLanguage()
  },
  methods: {
    /**
     * Handle closed modal
     */
    handleClosedModal () {
      this.registrationStep = 'registration'
      this.userEmail = ''
    },
    async handleLanguage () {
      const language = await UsersService.getCookie('user_language')
      if (language) {
        this.$i18n.locale = language
      } else {
        await UsersService.setCookie('user_language', 'pt_BR', 30)
        this.$i18n.locale = 'pt_BR'
      }
    },
    async handleLanguageChange () {
      await UsersService.setCookie('user_language', this.$i18n.locale, 30)
    },
    /**
     * Login user
     */
    async login () {
      this.loader = true
      try {
        const response = await AuthService.login(this.form)
        window.dataLayer.push({
          event: 'login',
          event_category: 'login',
          event_label: 'Login'
        })
        // If user email is not verified, show verification modal
        if (!response.is_email_verified) {
          this.registrationStep = 'verification'
          this.userEmail = response.email
          this.$bvModal.show('registration-modal')
        } else if (response.use_2fa) {
          this.userEmail = response.email
          this.$bvModal.show('2fa-modal')
        } else {
          location.reload()
        }
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
      // return
    }
  }
}
</script>

<style lang="scss" scoped>
  .logo {
    margin-bottom: 20px;
    img {
      width: 200px;
    }
  }
  .login {
    position: absolute;
    height: 100vh;
  }
</style>
