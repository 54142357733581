<template>
  <b-modal
    id="2fa-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
    @hidden="reset"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>{{ $t('enter_the_code') }}</span>
      </h2>
      <p>{{ $t('info_code_2fa') }}</p>
    </div>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <form @submit.prevent="submit" class="text-center default-form">
      <div class="form-group col-5 mx-auto">
        <label class="form-label">{{ $t('verification_code') }}</label>
        <input type="text" class="form-control" v-model.trim="$v.form.code.$model" placeholder="" />
      </div>
      <div class="text-center mt-3">
        <a href="#" @click.prevent="reSendCode" class="mb-3 d-block">
          <template v-if="isResending2FA">
            <Loader
              :size="'small'"
              :align="'start'"
            />
          </template>
          <template v-else>
            {{ $t('didnt_receive_it') }} <br>{{ $t('send_code_again') }}
          </template>
        </a>
        <Button
          type="submit"
          class="btn btn-primary"
          :disabled="$v.$invalid"
          :loading="loader"
        >
          {{ $t('enter') }}
        </Button>
      </div>
    </form>
  </b-modal>
</template>

<script>

import AuthService from '@/modules/auth/services/auth-service'
import { parseErrorResponseToArray } from '@/utils'
import { required } from 'vuelidate/lib/validators'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'

export default {
  name: 'TwoFactorAuthenticationModal',
  props: ['userEmail'],
  components: {
    AlertWraper,
    Button,
    Loader
  },
  data () {
    return {
      loader: false,
      isResending2FA: false,
      content: null,
      content_type: 'error',
      form: {
        code: '',
        email: ''
      }
    }
  },
  validations () {
    return {
      form: {
        code: { required },
        email: { required }
      }
    }
  },
  watch: {
    userEmail (email) {
      this.form.email = email
    }
  },
  methods: {
    /**
     * Resend 2FA code
     */
    async reSendCode () {
      this.isResending2FA = true
      this.content = null
      try {
        await AuthService.resend2FACode({ email: this.form.email })
        this.content_type = 'success'
        this.content = this.$t('info_new_code_2fa')
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
        this.content_type = 'error'
      }
      this.isResending2FA = false
    },
    reset () {
      this.form.code = ''
      this.content_type = 'error'
      this.content = null
    },
    /**
     * Send 2FA code
     */
    async submit () {
      this.loader = true
      this.content = null
      try {
        await AuthService.validate2FACode(this.form)
        location.reload()
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
        this.content_type = 'error'
      }
      this.loader = false
    }
  }
}
</script>
